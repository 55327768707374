import {
  Avatar,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
  Box,
} from "@mui/material";
import React from "react";
import LogoutIcon from "@mui/icons-material/Logout";
import { useAppDispatch, useToken } from "src/app/hooks";
import { authSignOut } from "src/features/auth/authSlice";
import { useCookies } from "react-cookie";
import { AUTH_TOKEN_COOKIE } from "src/features/auth/types";
import { useInstituteNameGet } from "src/app/services/apiHooks";
import { getInstitutionName } from "src/app/utils";

const Account = () => {
  const token = useToken();
  const { isLoading, data: institutionNameData } = useInstituteNameGet({
    token,
  });
  const dispatch = useAppDispatch();
  const [cookies, setCookie, removeCookie] = useCookies([AUTH_TOKEN_COOKIE]);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const signOut = () => {
    dispatch(authSignOut());
    removeCookie(AUTH_TOKEN_COOKIE, { path: "/" });
    window.location.reload();
  };

  const institutionName = getInstitutionName(institutionNameData);

  return (
    <div>
      <Box
        onClick={handleClick}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
        }}
      >
        <Avatar sx={{ mr: 2 }} />
        <Typography variant="body1" fontWeight="bold">
          {institutionName}
        </Typography>
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        disableAutoFocusItem
        PaperProps={{
          sx: {
            mt: 1,
            py: 1,
            width: 180,
          },
        }}
      >
        <MenuItem onClick={signOut}>
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>התנתקות</ListItemText>
        </MenuItem>
      </Menu>
    </div>
  );
};

export default Account;
