import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Dashboard from "src/app/components/Dashboard";
import AttendanceView from "src/features/attendance/components/AttendanceView";
import { RoutingPath } from "src/features/routing/types";
import SignIn from "src/features/auth/components/SignIn";
import RequireAuth from "src/features/auth/components/RequireAuth";
import { Box } from "@mui/material";
import SettingsView from "src/features/settings/components/SettingsView";

const App = () => {
  return (
    <Box sx={{ height: "100vh" }}>
      <Routes>
        <Route path="/signin" element={<SignIn />} />
        <Route
          path={RoutingPath.Home}
          element={
            <RequireAuth>
              <Dashboard />
            </RequireAuth>
          }
        >
          {attendanceViewTabs.map((tab) => (
            <Route
              key={`attendance-${tab}`}
              path={`${RoutingPath.Attendance}/${tab}`}
              element={<AttendanceView />}
            />
          ))}
          <Route
            path={RoutingPath.Attendance}
            element={<Navigate to={attendanceViewTabs[0]} replace />}
          />

          {settingsViewTabs.map((tab) => (
            <Route
              key={`settings-${tab}`}
              path={`${RoutingPath.Settings}/${tab}`}
              element={<SettingsView />}
            />
          ))}
          <Route
            path={RoutingPath.Settings}
            element={<Navigate to={settingsViewTabs[0]} replace />}
          />

          {/* Handle all other navigation fallbacks */}
          <Route
            path={"/"}
            element={<Navigate to={RoutingPath.Attendance} replace />}
          />
          <Route
            path={"*"}
            element={<Navigate to={RoutingPath.Attendance} replace />}
          />
        </Route>
      </Routes>
    </Box>
  );
};

export default App;

const attendanceViewTabs = ["general", "absences"];
const settingsViewTabs = ["general", "students", "attendance"];
