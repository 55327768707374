import React, { useMemo } from "react";
import MaterialTable, { Column } from "@material-table/core"; // using @material-table/core@next due to mui v5 breaking changes
import {
  Box,
  Paper,
  TablePagination,
  Theme,
  Typography,
  useTheme,
} from "@mui/material";
import tableIcons from "./TableIcons";
import localization from "src/app/components/Table/localization";
import TableToolbar from "src/app/components/Table/TableToolbar";

export type ToolbarComponents = {
  right?: React.ReactNode;
  left?: React.ReactNode;
};

type TableProps<T> = {
  title: string;
  columns: Column<any>[];
  data: T[];
  pageSize?: number;
  paging?: boolean;
  toolbarComponents?: ToolbarComponents;
  toolbarActions?: {
    isWithExport?: boolean;
  };
  onRowAdd?: (newData: T) => Promise<any>;
  onRowUpdate?: (newData: T, oldData?: T) => Promise<any>;
  onRowCancelUpdate?: (oldData: T) => Promise<any>;
  onRowDelete?: (oldData: T) => Promise<any>;
  isDeletable?: (rowData: T) => boolean;
};

const Table = <T extends object>({
  title,
  columns,
  data,
  pageSize = 10,
  paging = true,
  toolbarActions,
  toolbarComponents,
  onRowAdd,
  onRowUpdate,
  onRowCancelUpdate,
  onRowDelete,
  isDeletable = () => true,
}: TableProps<T>) => {
  const theme: Theme = useTheme();
  
  const memoizedColumns = useMemo(() => columns, [columns]);

  return (
    <MaterialTable<T>
      title={
        <Box sx={{ mr: 2 }}>
          <Typography variant="h6" color="textPrimary">
            {title}
          </Typography>
        </Box>
      }
      columns={memoizedColumns}
      icons={tableIcons}
      localization={localization}
      data={data.map((record, index) => ({
        ...Object.assign({}, record),
        id: (record as any).ID ?? index,
        tableData: { id: (record as any).ID ?? index }
      }))}
      editable={{ 
        onRowAdd: onRowAdd,
        onRowUpdate: onRowUpdate,
        onRowUpdateCancelled: onRowCancelUpdate,
        onRowDelete: onRowDelete,
        isDeletable: isDeletable,
      }}
      options={{
        search: true,
        searchFieldStyle: {
          marginRight: -20,
        },
        addRowPosition: "first", // can be changed if preferable by UX
        paging,
        pageSize,
        rowStyle: {
          fontFamily: theme.typography.fontFamily,
          fontSize: theme.typography.body2.fontSize,
          fontWeight: theme.typography.body2.fontWeight,
          lineHeight: theme.typography.body2.lineHeight,
        },
        exportAllData: true,
        tableLayout: "fixed",
      }}
      components={{
        Toolbar: (props) => (
          <TableToolbar
            data={data}
            columns={columns}
            tableTitle={title}
            MaterialTableProps={props}
            components={toolbarComponents}
            isWithExport={toolbarActions?.isWithExport}
          />
        ),
        Container: (props) => (
          <Paper
            {...props}
            variant="outlined"
            sx={{ borderRadius: 1.25, width: "100%" }}
          />
        ),
        Pagination: (props) => (
          <TablePagination {...props} sx={{ border: 0 }} />
        ),
      }}
    />
  );
};

// Eliminates the need of the component consumers to import directly from the internal table lib
export type TableColumn<T> = Column<any>;

export default Table;
