import React from "react";
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { useTheme, Box, Card, CardContent, CardHeader } from "@mui/material";
import { next30daysFormatted } from "src/app/utils/timeUtils";
import { useAppSelector, useToken } from "src/app/hooks";
import _ from "lodash";
import {
  START_OF_TODAY,
  TWENTY_NINE_DAYS_AHEAD,
} from "src/features/attendance/components/AttendanceDashboard";
import { absencesPerHourPerDay } from "src/features/attendance/components/AttendanceDashboard/utils";
import { useQuotaGet, useListAbsencesGet } from "src/app/services/apiHooks";
import { getDateFormat } from "src/app/components/Dashboard/dashboardViewSlice";

const AttendanceHeatmap: React.FC = () => {
  const theme = useTheme();
  const dateFormat = useAppSelector(getDateFormat);
  const NEXT_30_DAYS_FORMATTED = next30daysFormatted(dateFormat);
  const token = useToken();
  const { data: absences } = useListAbsencesGet({
    token,
    since: START_OF_TODAY,
    to: TWENTY_NINE_DAYS_AHEAD,
  });
  const { data: quota } = useQuotaGet({
    token,
    trustee: true,
  });

  if (_.isNil(absences) || _.isNil(quota)) return null;

  const numOfAbsencesPerHourPerDay = absencesPerHourPerDay(absences, quota);

  if (!numOfAbsencesPerHourPerDay || !numOfAbsencesPerHourPerDay.length) return null;

  const chartOptions: ApexOptions = {
    tooltip: {
      y: {
        formatter: (value) => `${value?.toFixed(1) || 0}%`,
        title: {
          formatter: (value) => `אחוז הנעדרים מתוך המכסה בשעה ${value || ''}: `,
        },
      },
    },
    chart: {
      height: 350,
      type: "heatmap",
      toolbar: {
        show: false,
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    colors: [theme.palette.primary.main],
    xaxis: {
      type: "category",
      categories: NEXT_30_DAYS_FORMATTED || [],
      tickAmount: NEXT_30_DAYS_FORMATTED?.length || 0,
      tickPlacement: "between",
    },
    yaxis: {
      labels: {
        padding: 0,
      },
    },
    plotOptions: {
      heatmap: {
        colorScale: {
          ranges: [
            {
              from: 0,
              to: 40,
              color: "#027FFE",
            },
            {
              from: 41,
              to: 50,
              color: theme.palette.secondary[100],
            },
            {
              from: 51,
              to: 60,
              color: theme.palette.secondary[200],
            },
            {
              from: 61,
              to: 70,
              color: theme.palette.secondary[300],
            },
            {
              from: 71,
              to: 80,
              color: theme.palette.secondary[400],
            },
            {
              from: 81,
              to: 90,
              color: theme.palette.secondary[500],
            },
            {
              from: 91,
              to: 500,
              color: theme.palette.secondary[600],
            },
          ],
        },
      },
    },
  };

  const series = numOfAbsencesPerHourPerDay || [];
  
  return (
    <Box
      sx={{
        backgroundColor: "background.default",
      }}
    >
      <Card
        variant="outlined"
        sx={{
          width: "100%",
        }}
      >
        <CardHeader title="תחזית ל-30 הימים הבאים" />
        <CardContent>
          <Chart
            options={chartOptions}
            series={series}
            type="heatmap"
            height={350}
          />
        </CardContent>
      </Card>
    </Box>
  );
};

export default AttendanceHeatmap;
